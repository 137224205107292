// 
// reboot.scss
//

html {
    position: relative;
    min-height: 100%;
}

// blockquote

.blockquote {
    padding: 10px 20px;
    border-left: 4px solid var(--#{$prefix}border-color-translucent);
}

.blockquote-reverse {
    border-left: 0;
    border-right: 4px solid var(--#{$prefix}border-color-translucent);
    text-align: right;
}

b,
strong {
    font-weight: $font-weight-semibold;
}

// row
.row>* {
    position: relative;
}