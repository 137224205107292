// 
// print.scss
//

// Used invoice page
@media print {

    .vertical-menu,
    .right-bar,
    .page-title-box,
    .navbar-header,
    .app-menu,
    .footer,
    #back-to-top {
        display: none !important;
    }

    .main-content,
    .right-bar,
    .page-content,
    body {
        padding: 0;
        margin: 0;
    }

    .card {
        border: 0;
        box-shadow: none !important;
    }

    .invoice-details {
        .d-sm-flex {
            display: flex !important;
        }
    }

    .address {
        &.col-sm-6 {
            flex: 0 0 auto !important;
            width: 50% !important;
            max-width: 100% !important;
        }
    }
}


.real-estate-widgets {
    .dashboard-nav {
        flex-wrap: inherit;

        .nav {
            gap: 5px;
            flex-shrink: 0;
        }

        .nav-item {
            .nav-link {
                display: flex;
                align-items: center;
                background-color: var(--#{$prefix}secondary-bg);
                border: none;
            }

            &.active {
                .nav-link {
                    color: var(--#{$prefix}body-color);
                    background-color: rgba(var(--#{$prefix}light-rgb), 0.5);
                }
            }
        }

        .tab-content {
            flex-grow: 1 !important;
            width: 100%;
        }
    }

}