//
// _datatables.scss
//

table:is(.dataTable) :is(td, th).dataTables_empty {
    text-align: center;
    padding: 50px;
    font-weight: $font-weight-semibold;
    --tb-table-accent-bg: var(--#{$prefix}secondary-bg);
}

table:is(.dataTable>thead) :is(.sorting, .sorting_asc, .sorting_desc, .sorting_asc_disabled, .sorting_desc_disabled):before {
    content: "\f0360";
    position: absolute;
    right: 0.5rem;
    top: 12px;
    font-size: var(--#{$prefix}font-sm);
    font-family: "Material Design Icons";
}

table:is(.dataTable>thead) :is(.sorting, .sorting_asc, .sorting_desc, .sorting_asc_disabled, .sorting_desc_disabled):after {
    content: "\f035d";
    position: absolute;
    right: 0.5rem;
    top: 18px;
    font-size: var(--#{$prefix}font-sm);
    font-family: "Material Design Icons";
}

table.dataTable>tbody>tr.child span.dtr-title {
    font-weight: $font-weight-semibold;
    min-width: 150px;
}


//responsive 
table {
    &.dataTable {
        &.dtr-inline {
            &.collapsed {
                >tbody {
                    >tr {

                        >th,
                        >td {
                            &.dtr-control {
                                &::before {
                                    background-color: var(--#{$prefix}primary);
                                    border-color: var(--#{$prefix}secondary-bg);
                                }
                            }
                        }

                        &.parent {
                            &.parent {
                                >th {
                                    &.dtr-control {
                                        &::before {
                                            background-color: $danger;
                                        }
                                    }
                                }
                            }

                            >td {
                                &.dtr-control {
                                    &::before {
                                        background-color: $danger;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        >tbody {
            >tr {
                &.child {
                    span {
                        &.dtr-title {
                            min-width: 135px;
                        }
                    }
                }
            }
        }
    }
}


.table-card {

    :is(.dataTables_length, .dataTables_filter) {
        padding: $card-cap-padding-y $card-cap-padding-x;
        padding-bottom: 0px;
    }

    :is(.dataTables_info, .dataTables_paginate) {
        padding: $card-cap-padding-y $card-cap-padding-x;
    }

    div.dataTables_wrapper {
        .col-md-6 {
            width: 100%;
        }

        div {
            &.dataTables_filter {
                input {
                    width: calc(100% - 52px);
                }

                label {
                    display: block;
                }
            }
        }
    }
}


div {
    &.dtr-modal {
        div {
            &.dtr-modal-display {
                background-color: var(--#{$prefix}secondary-bg);
                border-color: var(--#{$prefix}border-color);
                box-shadow: none;
                height: 70%;
            }

            &.dtr-modal-content {
                padding: $modal-inner-padding;

                h2 {
                    font-size: $font-size-lg;
                    font-weight: $font-weight-semibold;
                    margin-bottom: 15px;
                }

                .dtr-details {
                    tr {
                        td {
                            padding: $table-cell-padding-y $table-cell-padding-x;
                        }
                    }
                }
            }

            &.dtr-modal-close {
                font-size: 24px;
                top: 9px;
                right: 11px;
                width: 30px;
                height: 30px;
                line-height: 30px;
                background-color: var(--#{$prefix}secondary-bg);
                border-color: var(--#{$prefix}border-color);

                &:hover {
                    background-color: var(--#{$prefix}light);
                }
            }
        }
    }
}

.dt-buttons {

    :is(button.dt-button, div.dt-button, a.dt-button, input.dt-button) {
        border-color: var(--#{$prefix}border-color);
        background: var(--#{$prefix}light);
        color: var(--#{$prefix}body-color);
    }
}