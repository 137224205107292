//
// Dropzone
//

.dropzone {
  min-height: 230px !important;
  border: 2px dashed var(--#{$prefix}border-color) !important;
  background: var(--#{$prefix}secondary-bg) !important;
  border-radius: 6px !important;
  text-align: center;

  .dz-message {
    font-size: var(--#{$prefix}font-5xl);
    width: 100%;
    margin: 1em 0;
  }
}

[data-dz-thumbnail] {
  width: 100%;
  height: 100%;
}

.dropzone .dz-preview .dz-image {
  border-radius: 20px;
  overflow: hidden;
  width: 120px;
  height: 120px;
  position: relative;
  display: block;
  z-index: 10;
  background: var(--#{$prefix}light);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropzone .dz-preview .dz-image img {
  display: block;
  height: 90px;
  width: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropzone .dz-preview .dz-remove {
  margin-top: 10px;
  font-size: var(--#{$prefix}font-xs);
}

dropzone>.dropzone.dz-wrapper .dz-message {
  background-color: transparent !important;
  border: none !important;
  overflow: inherit !important;
  width: inherit !important;
  min-width: inherit !important;
  min-height: 180px !important;
  max-width: inherit !important;
  max-height: inherit !important;
  margin: 0px !important;
}

dropzone>.dropzone.dz-wrapper {
  min-height: 76px !important;
  border: none !important;
}