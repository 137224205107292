// 
// _listjs.scss
// 

.listjs-pagination{
    margin-bottom: 0;
    justify-content: flex-end;
    gap: 8px;
    li{
        .page{
            display: block;
            padding: $pagination-padding-y $pagination-padding-x;
            color: $pagination-color;
            background-color: $pagination-bg;
            border: $pagination-border-width solid $pagination-border-color;
            border-radius: $border-radius;
        }

        &.active{
            .page{
                color: $pagination-active-color;
                @include gradient-bg($pagination-active-bg);
                border-color: $pagination-active-border-color;
            }
        }
    }
}

.pagination-wrap {
    user-select: none;
    display: flex;
    align-items: center;
    a {
        text-decoration: none;
        display: inline-block;
        &.page-item{
            margin: 0;
        }
    }
    li{
        margin: 0;
    }
}

.pagination-next,
.pagination-prev {
    color: var(--#{$prefix}primary);
    font-weight: $font-weight-medium;
    padding: $pagination-padding-y $pagination-padding-x !important;
    background-color: $pagination-bg;
    border: $pagination-border-width solid $pagination-border-color;
    border-radius: $border-radius; 
    &:hover {
        color: var(--#{$prefix}primary-text);
    }

    &.disabled {
        color: var(--#{$prefix}secondary-color);
        cursor: default;

        &:hover {
            color: var(--#{$prefix}secondary-color);
        }
    }
}

li{
    &.pagination-next,
    &.pagination-prev {
        padding: 0 !important;
        border: 0 !important;
        background-color: transparent;
    }
}

.page-item.pagination-next{
    margin: 0;
}