//
// _form-check.scss
//

.form-check {
    position: relative;
    text-align: left
        /*rtl: right*/
    ;

    .form-check-input {
        cursor: pointer;
    }
}

.form-check-label {
    cursor: pointer;
    margin-bottom: 0;
}

// checkbox input right
.form-check-right {
    padding-left: 0;
    display: inline-block;

    .form-check-input {
        float: right;
        margin-left: 0;
        margin-right: $form-check-padding-start * -1;
    }

    .form-check-label {
        display: block;
    }

    &.form-switch {
        .form-check-input {
            margin-right: $form-check-padding-start * -1.6;
        }
    }
}

// Checkbox outline
.form-check-outline {
    .form-check-input {
        position: relative;

        &:checked[type=checkbox] {
            background-image: none;

            &::before {
                content: "\F012C";
                font-family: "Material Design Icons";
                top: -2px;
                position: absolute;
                font-weight: 700;
                font-size: 11px;
                left: 1px;
            }
        }
    }
}

// Switch sizes
.form-switch-md {
    padding-left: 2.5rem;
    min-height: 22px;
    line-height: 22px;

    .form-check-input {
        width: 40px;
        height: 20px;
        left: -0.5rem;
        position: relative;
    }

    .form-check-label {
        vertical-align: middle;
    }
}

.form-switch-lg {
    padding-left: 2.75rem;
    min-height: 28px;
    line-height: 28px;

    .form-check-input {
        width: 48px;
        height: 24px;
        left: -0.75rem;
        position: relative;
    }
}

.input-group-text {
    margin-bottom: 0px;
}

// Switch - Right

.form-switch-right {
    display: inline-block;
    padding-right: $form-check-padding-start * .5;
    margin-bottom: 0;
    padding-left: 0 !important;

    .form-check-input {
        float: right;
        margin-left: 0;
        margin-right: $form-check-padding-start * -1;
        margin-top: .1em !important;
    }

    label {
        margin-bottom: 0;
        margin-right: 1rem;
    }
}

// card radio
.card-radio {
    padding: 0;

    .form-check-label {
        background-color: var(--#{$prefix}secondary-bg);
        border: 1px solid var(--#{$prefix}border-color);
        border-radius: $border-radius;
        padding: 1rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
        position: relative;
        padding-right: 32px;

        &:hover {
            cursor: pointer;
        }
    }

    .form-check-input {
        display: none;

        &:checked+.form-check-label {
            border-color: $primary !important;

            &:before {
                content: '\eb80';
                font-family: "remixicon";
                position: absolute;
                top: 2px;
                right: 6px;
                font-size: var(--#{$prefix}font-lg);
                color: $primary;
            }
        }
    }

    &.dark {
        .form-check-input {
            &:checked+.form-check-label {
                &:before {
                    color: $white;
                }
            }
        }
    }
}

// Mixin Color
@each $state in map-keys($theme-colors) {
    .form-check-#{$state} {
        .form-check-input {
            &:checked {
                background-color: var(--#{$prefix}#{$state});
                border-color: var(--#{$prefix}#{$state});
            }
        }

        &.form-check-outline {
            .form-check-input {
                &:checked[type=checkbox] {
                    color: var(--#{$prefix}#{$state});
                    background-color: transparent;
                    border-color: var(--#{$prefix}#{$state});
                }
            }
        }
    }

    .form-radio-#{$state} {
        .form-check-input {
            &:checked {
                border-color: var(--#{$prefix}#{$state});
                background-color: var(--#{$prefix}#{$state});

                &:after {
                    background-color: var(--#{$prefix}#{$state});
                }
            }
        }
    }

    .form-switch-#{$state} {
        .form-check-input {
            &:checked {
                background-color: var(--#{$prefix}#{$state});
                border-color: var(--#{$prefix}#{$state});
            }
        }
    }
}