/*
Template Name: Steex - Admin & Dashboard Template
Author: Themesbrand
Version: 1.0.0
Website: https://Themesbrand.com/
Contact: Themesbrand@gmail.com
File: Icons Css File
*/

// Icons Scss
@import "plugins/icons/materialdesignicons";
@import "plugins/icons/boxicons";
@import "plugins/icons/remixicon";
@import "plugins/icons/bootstrap-icons";
@import "plugins/icons/phosphor";
